<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label
          v-if="field.type !== 'action'"
          :class="field.type === 'json' ? 'ma-0' : ''"
        >
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <div class="input-group">
            <v-autocomplete
              v-model="field.value"
              class="form-control mt-0"
              :class="validationState"
              :items="selectOptions"
              item-text="label"
              item-value="value"
              :no-data-text="$t('workflowDesigner.noSelectData')"
              :menu-props="{ offsetY: true }"
              :disabled="isDisabled"
              :multiple="true"
              hide-details
              @input="selectChange"
            />
            <div class="input-group-append" @click="showAddToken">
              <span class="input-group-text cursor-pointer">
                <i class="fas fa-add" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="addTokenModal"
      body-class="add-config-value"
      hide-footer
      hide-header
      size="lg"
    >
      <TokenEdit
        :return-to-route="false"
        @token-saved="tokenSaved"
        @token-create-cancel="$refs['addTokenModal'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import { bus } from "@/main";
import TokenEdit from "@/components/Projects/Settings/WorkflowToken/Edit";

export default {
  components: { TokenEdit },
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    validationState: function () {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.field.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (!this.field.value) {
        return "is-invalid";
      }
      return "is-valid";
    },
    selectOptions: function () {
      if (!this.field.options) {
        return [];
      }

      let options = this.field.options.map(o => {
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        let label = o.value;
        if (o.label) {
          label = o.label;
        } else if (this.$te(`${this.field.option}Options.${o.value}`)) {
          label = this.$t(`${this.field.option}Options.${o.value}`);
        }

        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });

      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      optionsSorted.forEach(option => {
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        newOptions.push(option);
      });
      return newOptions;
    }
  },
  watch: {
    "field.value": function () {
      this.edited = true;
    }
  },
  mounted() {
    if (this.field.value === undefined || this.field.value === null) {
      this.field.value = [];
    }
  },
  methods: {
    selectChange() {
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }

      setTimeout(() => {
        bus.$emit("checkRequiredFields");
      }, 100);
    },
    showAddToken() {
      this.$refs["addTokenModal"].show();
    },
    tokenSaved(token) {
      this.$refs["addTokenModal"].hide();
      this.field.options.push({
        value: token.id,
        label: token.label
      });
      this.field.value.push(token.id);
    }
  }
};
</script>
